(function ($) {
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery(document).ready(function ($) {
	$('#input_3_5_6').on('change.fs', function(){
		if($(this).val()){
			$('#field_3_19').show();
			$('#field_3_19 label').addClass('visible').addClass('full-visible');
			$('#field_3_19 li').addClass('visible').addClass('full-visible');
		} else {
			$('#field_3_19').hide();
		}
	}).trigger('change.fs');
	
    /**
     * Main User Interface object
     * @type {{ bootstrap: {placeholder: UI.bootstrap.placeholder, select: UI.bootstrap.select, anchor: UI..bootstrap.anchor, navigation: UI.bootstrap.navigation, tooltip:UI.bootstrap.tooltip, tooltip:UI.bootstrap.popup }, init: UI.init}}
     */
    var UI = {

        // Initializations on page load
        animations: {
            fancy_load: function () {
                $('.desktop .animation-section :header, .desktop .animation-section .btn, .desktop .animation-section form, .desktop .animation-section li, .desktop .animation-section p, .desktop .animation-section .line, .desktop .animation-section .date-box ').addClass("hidden").viewportChecker({
                    classToAdd: 'visible animated fadeIn',
                    offset: 50
                });
                $('.tablet .animation-section :header, .tablet .animation-section .btn, .tablet .animation-section form, .tablet .animation-section li, .tablet .animation-section p, .tablet .animation-section .line, .tablet .animation-section .date-box').addClass("hidden").viewportChecker({
                    classToAdd: 'visible animated fadeIn',
                    offset: 30
                });

            }
        },
        bootstrap: {
            select: function () {
                if ($('select').length) {
                    $('select').fancySelect({forceiOS: true});
                }
            },
            placeholder: function () {
                if ($('form').length > 0) {
                    $('input, textarea').placeholder();
                }
            },
            anchor: function () {
                if ($('.anchor-link').length) {
                    $('.anchor-link').on("click touchstart", function () {
                        var elementClick = $(this).attr("href");
                        var destination = $(elementClick).offset().top;
                        $('html,body').animate({scrollTop: destination}, 1100);
                        return false;
                    });
                }
                // Link to different page
                /*var hashURL = location.hash;
                 if (hashURL !== '' && hashURL.length > 1) {
                 $('html, body').scrollTop(0);
                 $('html').css({display: 'block'});
                 var targetY = $(hashURL).offset().top;
                 var targetYP = targetY - 60;
                 $("html, body").animate({
                 "scrollTop": targetYP
                 }, 1100);
                 } else {
                 $('html').css({display: 'block'});
                 }*/
            },
            navigation: function () {
                if (!$('.nav-toggle').length) {
                    return;
                }
                $('.nav-toggle').click(function () {
                    if ($('.nav-box').hasClass('open-nav')) {
                        $('.nav-box').removeClass('open-nav');
                        $('body').removeClass('open-nav');
                    } else {
                        $('.nav-box').addClass('open-nav');
                        $('body').addClass('open-nav');
                    }
                    return false;
                });
            },
            tooltip: function () {
                if ($('.tipso').length) {
                    $('.tipso').tipso({
                        background: 'rgba(242, 242, 242, 0.9)',
                        color: '#032b2f',
                        position: 'bottom',
                        width: 220
                    });
                }
            },
            popup: function () {
                if ($('a.fancybox').length) {
                    $("a.fancybox").fancybox({
                        maxWidth: 1000,
                        maxHeight: 630,
                        fitToView: false,
                        width: '90%',
                        height: '90%',
                        autoSize: false,
                        closeClick: false,
                        openEffect: 'none',
                        closeEffect: 'none',
                        ajax: {
                            complete: function (jqXHR, textStatus) {
                                $('select').fancySelect({forceiOS: true});
                            }
                        }
                    });
                }
                if ($('.run-lightbox').length) {
                    $('.run-lightbox a').on('click', function (e) { 
                        $('body').addClass('no-scroll');
                        $('#contact-block').fadeIn(300);
                        return false;
                    });
                    $('#contact-block .close').on('click', function (e) {
                        e.preventDefault();
                        $('#contact-block').fadeOut(300);
                        $('body').removeClass('no-scroll');
                    });
                }
            }
        },

        // Entry point
        init: function () {
            this.animations.fancy_load();     //Init animation sections
            this.bootstrap.placeholder();     // Init placeholder for old browsers
            this.bootstrap.select();         // Init select
            this.bootstrap.anchor();         // Init jump to an anchor
            this.bootstrap.navigation();    // Init navigation buttons
            this.bootstrap.tooltip();         // Init Tooltip
            this.bootstrap.popup();         // Init Popup
        }
    };
    var hashURL = location.hash;
    if (hashURL !== '' && hashURL.length > 1) {
        $('html, body').scrollTop(0);
        $('html').css({display: 'block'});
        ScrollTohash(hashURL);
    } else {
        $('html').css({display: 'block'});
    }
    if ($('.committes-class .sub-menu a').length > 0) {
        $(".committes-class .sub-menu a").on('click', function(){
            var url = $(this).attr('href') //get url
            var arr = url.split('#');
            var hash = "#"+arr[1];
            ScrollTohash(hash);
            return false;
        });
    }
    function ScrollTohash(anchor) {
        var targetY = $(anchor).offset().top;
        var targetYP = targetY - 10;
        $("html, body").animate({
            "scrollTop": targetYP
        }, 1100);
    }
    UI.init();
    var cont = jQuery('ul.hide-submenu-committes').html();
    jQuery('li.committes-class ul.sub-menu').html(cont);
		
		var cont = jQuery('ul.hide-submenu-committes2').html();
    jQuery('li.munuc-online ul.sub-menu').html(cont);
});
